import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function Biography() {
  return <Seo title={sections.Biography.title} />;
}

export default Biography;
